/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import TopBarProgress from "react-topbar-progress-indicator";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import Home from './pages/Home';
import { AuthContext } from './context/AuthContext';

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState("")
  const location = useLocation()

  useEffect(() => {
    setPrevLoc(location.pathname)
    setProgress(true)
    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }
  }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])

  return (
    <>
      {progress && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  )
}

function App() {
  const [userData, setUserData] = useState(null);
  const { currentUser } = useContext(AuthContext);

  TopBarProgress.config({
    barThickness: 5,
    barColors: {
      "0": "#ff0058",
      "0.5": "#ffbc00",
      "1.0": "#03a9f4",
    },
  });

  return (
    <Router basename={"/"}>
      <CustomSwitch>
        <Route exact path="/" element={<Home onUserDataChange={(val) => setUserData(val)} userData={userData} currentUserData={currentUser} />} />
      </CustomSwitch>
    </Router>
  );
}

export default App;
