/* eslint-disable jsx-a11y/img-redundant-alt */
import swal from '@sweetalert/with-react'
import { arrayUnion, doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { Avatar, Dropdown, Modal, RangeSlider } from 'flowbite-react'
import React, { useContext, useRef, useState } from 'react'
import { auth, db, storage } from '../firebase';
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import OpenAI from 'openai';
import { AuthContext } from '../context/AuthContext';
import { v4 as uuid } from "uuid";
import { useEffect } from 'react'
import moment from 'moment/moment'

const Home = () => {
    const [loading, setLoading] = useState(false);
    const [upLoading, setUpLoading] = useState(false);
    const [generating, setGenerating] = useState(false);
    // const [progressPercent, setProgressPercent] = useState();
    const [imageSize, setImageSize] = useState(50);
    const [user, setUser] = useState(null);
    const [url, setUrl] = useState('');
    const [prompt, setPrompt] = useState('');
    const [amount, setAmount] = useState('');
    const [openModal, setOpenModal] = useState('');
    const [firstTab, setFirstTab] = useState('File');
    const [secondTab, setSecondTab] = useState('Tablet');
    const [thirdTab, setThirdTab] = useState('Public');
    const provider = new GoogleAuthProvider();
    const { currentUser } = useContext(AuthContext);

    const imageInputRef = useRef();

    const openai = new OpenAI({
        apiKey: "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",
        dangerouslyAllowBrowser: true,
    });

    const SignIn = () => {
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        signInWithPopup(auth, provider)
            .then((result) => {
                setOpenModal(undefined)
                const user = result.user;
                setUser(user);
                setDoc(doc(db, "Users", result.user.uid), {
                    displayName: result.user.displayName,
                    email: result.user.email,
                    accessToken: result.user.accessToken,
                    photoURL: result.user.photoURL,
                    uid: result.user.uid,
                    time: currentTime,
                })
            }).catch((error) => {
                setOpenModal(undefined)
                const errorMessage = error.message;
                swal(errorMessage)
            });

    }

    const [image, setImage] = useState(null);
    const [publicCollectionImages, setPublicCollectionImages] = useState([]);
    const [privateCollectionImages, setPrivateCollectionImages] = useState([]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const fileSize = file.size / 1024
        const imgSize = 4 * 1024

        if (fileSize > imgSize) {
            swal("File size should not exceed 4 MB limit!!")
            setImage(null);
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            if (file) {
                reader.readAsDataURL(file);
            } else {
                setImage(null);
            }
        }
    };

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "PublicCollection", "Images"), (doc) => {
            setPublicCollectionImages(doc.data().Images);
        });
    }, [])

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "PrivateCollection", "Images"), (doc) => {
            setPrivateCollectionImages(doc.data().Images);
        });
    }, [])

    const handleUpload = async () => {
        const currentTimestamp = moment().format('x');
        if (firstTab === 'File') {
            const file = imageInputRef.current.files[0];

            try {
                setUpLoading(true);
                const image = await openai.images.createVariation({
                    image: file,
                });
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "key": "k86pBFyLXR0vOklxfny1HHWbEGtXqhYIkCo0sDx9y15UqtSFbEMYyaBjk5ZM",
                    "prompt": prompt,
                    "guidance_scale": 100,
                    "negative_prompt": "low quality",
                    "steps": 20,
                    "image": image.data[0].url,
                    "samples": 1,
                    "webhook": null,
                    "track_id": null
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                setUpLoading(false);

                fetch("https://stablediffusionapi.com/api/v5/outpaint", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        // console.log(JSON.parse(result).output);
                        const uid = uuid();
                        setGenerating(true);
                        JSON.parse(result).output.map((item, index) => (
                            fetch(item)
                                .then(response => response.arrayBuffer())
                                .then(buffer => {
                                    // Create a blob from the array buffer
                                    const blob = new Blob([buffer], { type: 'image/png' });
                                    // Now you can use the blob
                                    // console.log(blob);
                                    uploadBytes(ref(storage, `PublicCollection/${uid}`), blob).then((snapshot) => {
                                        // console.log('Uploaded a blob or file!');
                                        getDownloadURL(ref(storage, `PublicCollection/${uid}`)).then((downloadURL) => {
                                            // console.log(downloadURL)
                                            updateDoc(doc(db, "PublicCollection", 'Images'), {
                                                Images: arrayUnion({
                                                    downloadURL,
                                                    time: currentTimestamp,
                                                }),
                                            })
                                        })
                                    });
                                })
                                .catch(error => { console.error('Error:', error); setGenerating(false); })
                        ));
                    })
                    .catch(error => { console.error('error', error); setGenerating(false); });
            } catch (error) {
                setUpLoading(false);
                console.error('Error fetching data: ', error);
            }
        } else {
            setUpLoading(true);
            const uid = uuid();
            fetch(url)
                // .then(response => {
                //     // Get the content type of the file
                //     const contentType = response.headers.get('content-type');
                //     contentType !== 'image/png' && swal("JPEG/JPG is not supported!!")
                //     // Now you can use the content type
                //     console.log(contentType);
                // })
                .then(res => res.blob())
                .then(async (blob) => {
                    const file = new File([blob], `${uid}.png`, { type: 'image/png' });
                    // console.log(file);
                    const image = await openai.images.createVariation({
                        image: file,
                    });
                    // console.log(image.data);

                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "key": "k86pBFyLXR0vOklxfny1HHWbEGtXqhYIkCo0sDx9y15UqtSFbEMYyaBjk5ZM",
                        "prompt": prompt,
                        "guidance_scale": 100,
                        "negative_prompt": "low quality",
                        "steps": 20,
                        "image": image.data[0].url,
                        "samples": 1,
                        "webhook": null,
                        "track_id": null
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    setUpLoading(false);

                    fetch("https://stablediffusionapi.com/api/v5/outpaint", requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            // console.log(JSON.parse(result).output);
                            const uid = uuid();
                            setGenerating(true);
                            JSON.parse(result).output.map((item, index) => (
                                fetch(item)
                                    .then(response => response.arrayBuffer())
                                    .then(buffer => {
                                        // Create a blob from the array buffer
                                        const blob = new Blob([buffer], { type: 'image/png' });
                                        // Now you can use the blob
                                        // console.log(blob);
                                        uploadBytes(ref(storage, `PublicCollection/${uid}`), blob).then((snapshot) => {
                                            // console.log('Uploaded a blob or file!');
                                            getDownloadURL(ref(storage, `PublicCollection/${uid}`)).then((downloadURL) => {
                                                // console.log(downloadURL)
                                                updateDoc(doc(db, "PublicCollection", 'Images'), {
                                                    Images: arrayUnion({
                                                        downloadURL,
                                                        time: currentTimestamp,
                                                    }),
                                                })
                                            })
                                        });
                                    })
                                    .catch(error => { console.error('Error:', error); setGenerating(false); })
                            ));
                        })
                        .catch(error => { console.error('error', error); setGenerating(false); });
                })
                .catch(error => console.error('Error:', error));
        }
    }

    return (
        <div className="RootApp min-h-screen min-w-full bg-slate-800">
            <Modal dismissible show={openModal === 'dismissible'} onClose={() => setOpenModal(undefined)} className='bg-transparent'>
                <Modal.Header>
                    Sign in
                </Modal.Header>
                <Modal.Body>
                    <div className="completeDiv w-full flex justify-center items-center">
                        <div className="GoogleSignin flex w-fit justify-center items-center gap-2 flex-row bg-slate-400 rounded-lg p-2 cursor-pointer" onClick={SignIn}>
                            <img src="https://cdn-icons-png.flaticon.com/512/281/281764.png" alt="Google Icon" className="h-5 w-5" />
                            <span>Login With Google</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="AppHeader p-5 bg-slate-700 flex justify-between items-center">
                <div className="AppLogo text-white">
                    Extend My Image
                </div>
                {currentUser ?
                    <>
                        <div className="Profile flex justify-center items-center">
                            <Dropdown
                                arrowIcon={false}
                                inline
                                label={<Avatar alt="User settings" img={currentUser.photoURL} rounded className='mr-2' />}
                            >
                                <Dropdown.Header>
                                    <span className="block text-sm">
                                        {currentUser.displayName}
                                    </span>
                                    <span className="block text-sm">
                                        {currentUser.email}
                                    </span>
                                </Dropdown.Header>
                                {/* <Link to={'/Profile/' + currentUser.uid}>
                                    <Dropdown.Item>
                                        Profile
                                    </Dropdown.Item>
                                </Link>
                                <Dropdown.Divider /> */}
                                <Dropdown.Item onClick={() => signOut(auth)}>
                                    Sign out
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </>
                    :
                    <div className="SignInBtn flex justify-center items-center gap-2">
                        <div className="LoginIcon">
                            <img src="https://cdn-icons-png.flaticon.com/512/3889/3889524.png" alt="Login Icon" className="h-5 w-5 text-white" />
                        </div>
                        <div className="LoginText text-white cursor-pointer" onClick={() => setOpenModal('dismissible')}>Sign In</div>
                    </div>
                }
            </div>
            <div className="AppBody p-5 flex flex-wrap justify-between items-start gap-5">
                <div className="LeftPanel flex-1 bg-slate-700 p-2 flex flex-col rounded-lg">
                    <div className="UploadOptionBtn bg-slate-600 flex flex-row justify-center items-center gap-2 p-2 rounded-lg w-fit">
                        {firstTab === 'File' ? <div className="UploadFileBtn bg-slate-800 rounded-lg p-2 text-white cursor-pointer" onClick={() => { setFirstTab('File'); setImage(null) }}>Upload File</div> : <div className="UploadFileBtn rounded-lg p-2 text-white cursor-pointer" onClick={() => { setFirstTab('File'); setImage(null) }}>Upload File</div>}
                        {firstTab === 'URL' ? <div className="UploadURLBtn bg-slate-800 rounded-lg p-2 text-white cursor-pointer" onClick={() => { setFirstTab('URL'); setImage(null) }}>Upload URL</div> : <div className="UploadURLBtn rounded-lg p-2 text-white cursor-pointer" onClick={() => { setFirstTab('URL'); setImage(null) }}>Upload URL</div>}
                    </div>
                    {firstTab === 'File' ?
                        <div className="UploadImageArea bg-slate-600 border-dashed border-2 border-sky-500 mt-10 rounded-2xl w-full">
                            <input type="file" accept="image/png" name="imageUpload" id="imageUpload" className='hidden' ref={imageInputRef} onChange={handleImageChange} />
                            {image !== null ?
                                <label htmlFor="imageUpload">
                                    <div className="UsersUploadedImage p-5 flex flex-wrap justify-between items-center">
                                        <img src={image} alt="Image" className='h-20 w-20 rounded-lg' />
                                        <div className="ImageDetails flex flex-col text-white gap-2">
                                            <span className='fileName'>{imageInputRef.current.files[0].name}</span>
                                            <span>{Math.round(imageInputRef.current.files[0].size / 1024)} KB</span>
                                        </div>
                                    </div>
                                </label>
                                :
                                <label htmlFor="imageUpload">
                                    <div className="AreaForUpload h-60 w-full flex justify-center items-center text-center flex-col gap-2 px-2">
                                        <div className="UploadIcon flex justify-center items-center text-center w-full">
                                            <img src="https://cdn-icons-png.flaticon.com/512/6597/6597441.png" alt="Upload Icon" className="h-5 w-5" />
                                        </div>
                                        <div className="UploadText flex justify-center items-center text-center w-full text-white">
                                            Upload an Image
                                        </div>
                                        <div className="UploadDescription flex justify-center items-center text-center w-full text-white">
                                            To get better results, upload an image without borders or lettering
                                        </div>
                                        <div className="UploadSize flex justify-center items-center text-center w-full text-red-400">
                                            (Upto 4MB)
                                        </div>
                                        <div className="UploadSupported flex justify-center items-center text-center w-full text-white">
                                            PNG is supported
                                        </div>
                                    </div>
                                </label>
                            }
                        </div>
                        :
                        <>
                            <div className="UsersPrompt w-full mt-5 flex flex-col gap-2">
                                <div className="PromptHeading text-white flex flex-row gap-2">
                                    <span className="font-extrabold">URL</span>
                                </div>
                                <div className="PromptInput text-white font-semibold">
                                    <input type="text" name="url" id="url" placeholder="Enter URL" className='border-2 border-slate-500 rounded-lg w-full bg-transparent' value={url} onChange={(e) => setUrl(e.target.value)} />
                                </div>
                            </div>
                        </>
                    }
                    <div className="UsersPrompt w-full mt-5 flex flex-col gap-2">
                        <div className="PromptHeading text-white flex flex-row gap-2">
                            <span className="font-extrabold">Prompt</span>
                            <span className="text-slate-500">(Optional)</span>
                        </div>
                        <div className="PromptDescription text-white font-semibold">
                            Describe the image you want to generate in English
                        </div>
                        <div className="PromptInput text-white font-semibold">
                            <input type="text" name="prompt" id="prompt" placeholder="Enter Prompt" className='border-2 border-slate-500 rounded-lg w-full bg-transparent' value={prompt} onChange={(e) => setPrompt(e.target.value)} />
                        </div>
                    </div>
                    <div className="UsersAmount w-full mt-5 flex flex-col gap-2">
                        <div className="AmountHeading text-white">
                            <span className="font-extrabold">Amount</span>
                        </div>
                        <div className="AmountDescription text-white font-semibold">
                            Set the number of images you want to generate
                        </div>
                        <div className="AmountInput text-white font-semibold">
                            <input type="number" name="Amount" id="Amount" placeholder="Enter Amount" className='border-2 border-slate-500 rounded-lg w-full bg-transparent' value={amount} onChange={(e) => setAmount(e.target.value)} />
                        </div>
                    </div>
                    <div className="UsersVisibility w-full mt-5 flex flex-col gap-2">
                        <div className="VisibilityHeading text-white">
                            <span className="font-extrabold">Private</span>
                        </div>
                        <div className="VisibilityDescription text-white font-semibold">
                            Control the privacy of the image
                        </div>
                        <div className="VisibilityInput text-white font-semibold">
                            <input type="text" name="Visibility" id="Visibility" placeholder="Enter Visibility" className='border-2 border-slate-500 rounded-lg w-full bg-transparent' />
                        </div>
                        <div className="CreateMoreText underline text-white">
                            To create private images you must first <strong>upgrade</strong>
                        </div>
                    </div>
                    {currentUser ?
                        <div className="ExtendBtn w-full text-white flex justify-center items-center text-center bg-red-400 rounded-lg p-5 mt-10 hover:bg-red-500 cursor-pointer" onClick={handleUpload}>
                            Extend My Image
                        </div>
                        :
                        <div className="ExtendBtn w-full text-white flex justify-center items-center text-center bg-red-400 rounded-lg p-5 mt-10 hover:bg-red-500 cursor-pointer" onClick={() => setOpenModal('dismissible')}>
                            Extend My Image
                        </div>
                    }
                </div>
                <div className="RightPanel flex-1 bg-slate-700 p-2 flex flex-col justify-center items-center rounded-lg">
                    <div className={`DeviceOrientation w-full flex flex-row justify-end items-center gap-5 ${secondTab === 'Phone' && 'mb-20'}`}>
                        <div className={`OrientationTablet p-2 rounded-xl ${secondTab === 'Tablet' ? 'bg-slate-500' : 'bg-slate-600'} cursor-pointer`} onClick={() => setSecondTab('Tablet')}>
                            <img src="https://cdn-icons-png.flaticon.com/512/1530/1530273.png" alt="Tablet Icon" className="h-10 w-10" />
                        </div>
                        <div className={`OrientationPhone p-2 rounded-xl ${secondTab === 'Phone' ? 'bg-slate-500' : 'bg-slate-600'} cursor-pointer`} onClick={() => setSecondTab('Phone')}>
                            <img src="https://cdn-icons-png.flaticon.com/512/7291/7291264.png" alt="Phone Icon" className="h-10 w-10" />
                        </div>
                        <div className={`OrientationPC p-2 rounded-xl ${secondTab === 'Computer' ? 'bg-slate-500' : 'bg-slate-600'} cursor-pointer`} onClick={() => setSecondTab('Computer')}>
                            <img src="https://cdn-icons-png.flaticon.com/512/2344/2344269.png" alt="PC Icon" className="h-10 w-10" />
                        </div>
                    </div>
                    <div className={`WhiteBlackChecks mt-10 flex justify-center items-center p-2 ${secondTab === 'Tablet' && 'aspect-square w-4/5'} ${secondTab === 'Phone' && 'aspect-video w-4/5 rotate-90'} ${secondTab === 'Computer' && 'aspect-video w-4/5'} max-h-full max-w-full`}>
                        {image && <img src={image} alt="Image" className={`h-[${imageSize}%] w-[${imageSize}%] rounded-xl`} style={{ height: `${imageSize}%`, width: `${imageSize}%` }} />}
                    </div>
                    <div className={`RangeSlider px-5 py-2 w-full mt-12 ${secondTab === 'Phone' && 'mt-28'}`}>
                        <div className="SlidingRange bg-slate-600 p-5 rounded-lg">
                            <RangeSlider
                                id="md-range"
                                sizing="md"
                                min={10}
                                max={100}
                                value={imageSize}
                                onChange={(e) => setImageSize(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="AppBottom bg-slate-800 p-5 flex flex-col">
                <div className="UploadOptionBtn bg-slate-600 flex flex-row justify-center items-center gap-2 p-2 rounded-lg w-fit">
                    {thirdTab === 'Public' ? <div className="UploadFileBtn rounded-lg p-2 text-white bg-slate-800 cursor-pointer" onClick={() => setThirdTab('Public')}>Public</div> : <div className="UploadFileBtn rounded-lg p-2 text-white cursor-pointer" onClick={() => setThirdTab('Public')}>Public</div>}
                    {thirdTab === 'Collection' ? <div className="UploadURLBtn rounded-lg p-2 text-white bg-slate-800 cursor-pointer" onClick={() => setThirdTab('Collection')}>My Collection</div> : <div className="UploadURLBtn rounded-lg p-2 text-white cursor-pointer" onClick={() => setThirdTab('Collection')}>My Collection</div>}
                </div>
                <div className="Images w-full flex flex-wrap justify-start items-center mt-10 gap-6">
                    {thirdTab === 'Public' && publicCollectionImages.sort((a, b) => b.time - a.time).map((item, index) => (
                        <img src={item.downloadURL} alt="Public Images" className="h-60 w-60 rounded-xl" key={index} />
                    ))}
                    {thirdTab === 'Collection' && privateCollectionImages.sort((a, b) => b.time - a.time).map((item, index) => (
                        <img src={item.downloadURL} alt="Private Images" className="h-60 w-60 rounded-xl" key={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Home